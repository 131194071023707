/* eslint-disable react/no-unescaped-entities */
import Img from "gatsby-image";
import React from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import MuiIcons from "../MuiIcons";

const Benefits = ({ data }) => {
  const theme = useTheme();
  return (
    <Grid container spacing={4}>
      {data.map((benefit, i) => (
        <Grid item xs={12} sm={6} md={4} key={i}>
          <Box
            component={Card}
            padding={4}
            borderRadius={2}
            width={1}
            height={1}
            data-aos={"fade-up"}
            data-aos-delay={i * 100}
          >
            <Box display={"flex"} flexDirection={"column"}>
              {benefit.icon && (
                <Box
                  component={Avatar}
                  width={50}
                  height={50}
                  marginBottom={2}
                  bgcolor={theme.palette.primary.main}
                  color={theme.palette.background.paper}
                >
                  <MuiIcons icon={benefit.icon} />
                </Box>
              )}
              {benefit.title && (
                <Typography
                  variant={"h6"}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  {benefit.title}
                </Typography>
              )}
              {benefit.cover &&
                <Box component={Card}>
                  {/* <Box
                    component={CardMedia}
                    height={1}
                    width={1}
                    minHeight={300}
                  // image="https://assets.maccarianagency.com/backgrounds/img4.jpg"
                  /> */}
                  <Img fluid={benefit.cover.childImageSharp.fluid} />
                </Box>
              }
              <br />
              {benefit.paragraph && (
                <Typography
                  color="text.secondary"
                  dangerouslySetInnerHTML={{
                    __html: `${benefit.paragraph}`,
                  }}
                />
              )}
              {benefit.list && (
                <>
                  {benefit.list.map((list, i) => (
                    <Typography
                      color={"text.secondary"}
                      dangerouslySetInnerHTML={{
                        __html: `- ${list.paragraph}`,
                      }}
                    />
                  ))}
                </>
              )}
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

Benefits.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string,
    icon: PropTypes.string,
  }),
};

export default Benefits;
