/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";

import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const QuickStart = ({ data }) => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          {data.title && (
            <Typography
              variant="h4"
              color="text.primary"
              align={"center"}
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              {data.title}
            </Typography>
          )}
          {data.paragraph && (
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{ fontWeight: 400 }}
              align={"center"}
              dangerouslySetInnerHTML={{
                __html: `${data.paragraph}`,
              }}
            />
          )}
        </Box>
      </Box>
      {data.code && (
        <Box
          component={SyntaxHighlighter}
          language={data.code.lang}
          style={vs2015}
          padding={`${theme.spacing(2)} !important`}
          borderRadius={2}
          margin={`${theme.spacing(0)} !important`}
          bgcolor={"#21325b !important"}
        >
          {data.code.code}
        </Box>
      )}
    </Box>
  );
};

QuickStart.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string,
    code: PropTypes.object,
  }),
};

export default QuickStart;
