import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { alpha, useTheme } from "@mui/material/styles";

import { Link } from "gatsby";

const Buttons = ({ data }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  return (
    <>
      {data.map((button, i) => (
        <Box
          key={i}
          marginTop={{ xs: 2, sm: 0 }}
          marginRight={{ sm: 2 }}
          width={{ xs: "100%", md: "auto" }}
        >
          {button.type === "internal" && (
            <Link to={button.link}>
              <Button
                component={"div"}
                variant={button.style === "primary" ? "contained" : "outlined"}
                color={"primary"}
                size="large"
                fullWidth={isMd ? false : true}
                href={button.link}
              >
                {button.button}
              </Button>
            </Link>
          )}
          {button.type === "external" && (
            <Button
              key={i}
              component={"a"}
              variant={button.style === "primary" ? "contained" : "outlined"}
              color={"primary"}
              size="large"
              fullWidth={isMd ? false : true}
              href={button.link}
              target="_blank"
            >
              {button.button}
            </Button>
          )}
        </Box>
      ))}
    </>
  );
};

Buttons.propTypes = {
  buttons: PropTypes.array.isRequired,
};

export default Buttons;
