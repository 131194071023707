import React from "react";
import PropTypes from "prop-types";

import AcUnitIcon from "@material-ui/icons/AcUnit";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import TocIcon from "@material-ui/icons/Toc";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PolylineIcon from '@mui/icons-material/Polyline';

//    https://v4.mui.com/components/material-icons/

const MuiIcons = ({ icon }) => {
  switch (icon) {
    case "AcUnit":
      return <AcUnitIcon />;
    case "AutoAwesome":
      return <AutoAwesomeIcon />;
    case "AccessAlarm":
      return <AccessAlarmIcon />;
    case "Accessibility":
      return <AccessibilityIcon />;
    case "Toc":
      return <TocIcon />;
    case "DataUsage":
      return <DataUsageIcon />;
    case "CloudDone":
      return <CloudDoneIcon />;
    case "ControlCamera":
      return <ControlCameraIcon />;
    case "Polyline":
      return <PolylineIcon />;
    default:
      return icon;
      break;
  }
};

MuiIcons.propTypes = {
  nameIcon: PropTypes.string.isRequired,
};

export default MuiIcons;
