/* eslint-disable react/no-unescaped-entities */
import Img from "gatsby-image";
import React, { useState } from "react";
import PropTypes from "prop-types";

import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Features = ({ data }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? "row" : "column-reverse"}>
        <Grid item xs={12} md={6}>
          <Box marginBottom={4}>
            {data.title && (
              <Typography sx={{ fontWeight: 700 }} variant={"h4"} gutterBottom>
                {data.title}
              </Typography>
            )}
            {data.paragraph && (
              <Typography
                variant={"h6"}
                component={"p"}
                color={"text.secondary"}
                dangerouslySetInnerHTML={{
                  __html: `${data.paragraph}`,
                }}
              />
            )}
            {data.list && (
              <>
                {data.list.map((list, i) => (
                  <Typography
                    // variant={"h6"}
                    component={"p"}
                    color={"text.secondary"}
                    dangerouslySetInnerHTML={{
                      __html: `- ${list.paragraph}`,
                    }}
                  />
                ))}
              </>
            )}
          </Box>
          <Grid container spacing={2}>
            {data.feature.map((feature, i) => (
              <Grid key={i} item xs={12} md={4}>
                {feature.number && feature.symbol && (
                  <Typography variant="h4" color={"primary"} gutterBottom>
                    <VisibilitySensor
                      onChange={(isVisible) => setViewPortVisibility(isVisible)}
                      delayedCall
                    >
                      <CountUp
                        duration={2}
                        end={viewPortEntered ? feature.number : 0}
                        start={0}
                        suffix={feature.symbol}
                      />
                    </VisibilitySensor>
                  </Typography>
                )}
                {feature.paragraph && (
                  <Typography
                    color="text.secondary"
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: `${feature.paragraph}`,
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          <Box component={Card} boxShadow={4} height={1} width={1}>
            <Box
              component={CardMedia}
              height={1}
              width={1}
              minHeight={300}
              image="https://assets.maccarianagency.com/backgrounds/img4.jpg"
            />
            {/* <Img fluid={data.cover.childImageSharp.fluid} /> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

Features.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string,
    feature: PropTypes.array,
  }),
};

export default Features;
