import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

import SEO from "../components/SEO";
import Layout from "../components/Layout";
import locales from "../constants/locales";

import { alpha, useTheme } from "@mui/material/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "../components/TheFront/Container";

import {
  Services,
  Hero,
  QuickStart,
  Features,
  Benefits,
  GetStarted,
} from "../components/TheFront";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(0, 113, 233)",
    },
  },
});

const Landing = ({ pageContext: { locale }, data }) => {
  const landing = data.markdownRemark;
  return (
    <Layout locale={locale}>
      <SEO
        title={`TriDyme | ${landing.frontmatter.title}`}
        lang={locale}
        url={
          locales[locale].default
            ? `/${landing.frontmatter.slug}`
            : `/${locales[locale].path}/${landing.frontmatter.slug}`
        }
        image={
          locales[locale].default
            ? `/${landing.frontmatter.seo.featuredImage?.publicURL}`
            : `/${locales[locale].path}${landing.frontmatter.seo.featuredImage?.publicURL}`
        }
        description={landing.frontmatter.seo.description}
        author={landing.frontmatter.seo.author}
      />

      <ThemeProvider theme={theme}>
        <Box sx={{ overflow: "hidden" }}>
          {landing.frontmatter.hero && <Hero data={landing.frontmatter.hero} />}
          <Box
            sx={{
              backgroundImage: `linear-gradient(to bottom,white,rgb(247, 250, 255) 100%)`,
              backgroundRepeat: "repeat-x",
              position: "relative",
            }}
          >
            {landing.frontmatter.services && (
              <Container>
                <Services data={landing.frontmatter.services} />
              </Container>
            )}
            {landing.frontmatter.quickstart && (
              <Container maxWidth={600}>
                <QuickStart data={landing.frontmatter.quickstart} />
              </Container>
            )}
            {landing.frontmatter.features && (
              <Container>
                <Features data={landing.frontmatter.features} />
              </Container>
            )}
            {landing.frontmatter.benefits && (
              <Container>
                <Benefits data={landing.frontmatter.benefits} />
              </Container>
            )}
            <Box
              component={"svg"}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
              sx={{
                width: "100%",
                marginBottom: "-8px",
              }}
            >
              <path
                fill={"white"}
                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
              ></path>
            </Box>
          </Box>
          {landing.frontmatter.get_started && (
            <Container>
              <GetStarted data={landing.frontmatter.get_started} />
            </Container>
          )}
        </Box>
      </ThemeProvider>
    </Layout>
  );
};

Landing.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default Landing;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        date(formatString: "DD-MM-YYYY")
        seo {
          featuredImage {
            publicURL
          }
          author
          description
        }
        hero {
          title
          paragraph
          animated_words
          covers {
            cover {
              childImageSharp {
                fluid(maxWidth: 500) {
                  src
                }
              }
            }
          }
          buttons {
            button
            link
            type
            style
          }
        }
        services {
          title
          paragraph
          service {
            icon
            title
            paragraph
          }
        }
        quickstart {
          title
          paragraph
          code {
            code
            lang
          }
        }
        features {
          title
          paragraph
          cover {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          list {
            paragraph
          }
          feature {
            symbol
            number
            paragraph
          }
        }
        benefits {
          icon
          title
          cover {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          paragraph
          list {
            paragraph
          }
        }
        get_started {
          title
          paragraph
          buttons {
            button
            link
            type
            style
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
