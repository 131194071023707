/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";

import MuiIcons from "../MuiIcons";

const Services = ({ data }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          {data.title && (
            <Typography
              variant="h4"
              color="text.primary"
              align={"center"}
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              {data.title}
            </Typography>
          )}
          {data.paragraph && (
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{ fontWeight: 400 }}
              align={"center"}
              dangerouslySetInnerHTML={{
                __html: `${data.paragraph}`,
              }}
            />
          )}
        </Box>
      </Box>
      {data.service && (
        <Grid container spacing={2} justifyContent="center">
          {data.service.map((service, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box width={1} height={1}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  {service.icon && (
                    <Box
                      component={Avatar}
                      width={60}
                      height={60}
                      marginBottom={2}
                      bgcolor={alpha(theme.palette.primary.main, 0.1)}
                      color={theme.palette.primary.main}
                    >
                      {/* {service.icon} */}
                      <MuiIcons icon={service.icon} />
                    </Box>
                  )}
                  {service.title && (
                    <Typography
                      variant={"h6"}
                      gutterBottom
                      sx={{ fontWeight: 500 }}
                      align={"center"}
                    >
                      {service.title}
                    </Typography>
                  )}
                  {service.paragraph && (
                    <Typography align={"center"} color="text.secondary">
                      {service.paragraph}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

Services.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string,
    service: PropTypes.array,
  }),
};

export default Services;
