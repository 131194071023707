import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Buttons from "../Buttons";

const GetStarted = ({ data }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box>
      {data.title && (
        <Typography
          variant="h4"
          color="text.primary"
          align={"center"}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          {data.title}
        </Typography>
      )}
      {data.paragraph && (
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          sx={{ fontWeight: 400 }}
          align={"center"}
          dangerouslySetInnerHTML={{
            __html: `${data.paragraph}`,
          }}
        />
      )}
      {data.buttons && (
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "stretched", sm: "flex-start" }}
          justifyContent={"center"}
          marginTop={4}
        >
          <Buttons data={data.buttons} />
        </Box>
      )}
    </Box>
  );
};

GetStarted.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string,
    buttons: PropTypes.array,
  }),
};

export default GetStarted;
